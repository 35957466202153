<template>
  <div>
    <title>SIM INVENTORY ~ PRODUCTION RESULTS DETAILS LIST DATA</title>
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <h1>
        Production Results Detail List Data
        <br />
        <h4>
          The following is a list of transaction data lists for production
          results details
        </h4>
        <!-- <small>Control panel</small> -->
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Dashboard</li>
      </ol>
    </section>

    <!-- Main content -->
    <section class="content">
      <!-- Small boxes (Stat box) -->
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>

      <div class="row">
        <div class="col-xs-12">
          <div class="box box-primary">
            <div class="box-header">
              <i class="fa fa-list"></i>
              <h3 class="box-title">
                Production Results Detail List
                <b style="color: red">{{ prodnumber }}</b>
              </h3>
            </div>

            <div class="box-body">
              <div class="box-body">
                <div class="row">
                  <div class="col-md-3">
                    Show &nbsp; :
                    <select
                      v-model="pageSize"
                      @change="handlePageSizeChange($event)"
                    >
                      <option
                        v-for="size in pageSizes"
                        :key="size"
                        :value="size"
                      >
                        {{ size }}
                      </option>
                    </select>
                    results
                  </div>
                </div>
              </div>
            </div>
            <!-- /.box-header -->
            <div v-if="isExist" class="box-body">
              <input
                type="hidden"
                class="form-control"
                readonly
                name="prodnumber"
                v-model="prodnumber"
                id="prodnumber"
              />
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Item Code</th>
                      <th>Outgoing Number</th>
                      <th>Receive Number</th>
                      <th>CIF</th>
                      <th>CIF Rp.</th>
                      <th>Unit Price</th>
                      <th>Qty</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(classdata, index) in list_paged"
                      :key="classdata.id"
                    >
                      <td>{{ index + 1 + (page - 1) * pageSize }}</td>
                      <td>{{ classdata.kode_barang }}</td>
                      <td>{{ classdata.outgoing_number }}</td>
                      <td>{{ classdata.receipt_number }}</td>
                      <td>{{ classdata.cif }}</td>
                      <td>Rp. {{ formatPrice(classdata.cif_rupiah) }}</td>
                      <td>{{ classdata.harga_satuan }}</td>
                      <td>{{ classdata.qty }} &nbsp; {{ classdata.unit }}</td>
                    </tr>
                  </tbody>
                </table>
                <v-pagination
                  v-model="page"
                  :pages="count"
                  :range-size="1"
                  active-color="#DCEDFF"
                  @update:modelValue="handlePageChange"
                />
              </div>
            </div>
            <div v-else class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Item Code</th>
                      <th>Outgoing Number</th>
                      <th>Receive Number</th>
                      <th>CIF</th>
                      <th>CIF Rp.</th>
                      <th>Unit Price</th>
                      <th>Qty</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="8" class="text-center">
                          <b style="color: red"> ... Data Not Found ... </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- /.box-body -->
          </div>
        </div>
      </div>

      <!-- /.row -->
      <!-- Main row -->
    </section>
  </div>
</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import swal from "sweetalert";
import axios from "axios";

export default {
  name: "vread",
  components: {
    VPagination,
  },
  data() {
    return {
      isShow: false,
      isExist: true,
      isExist2: true,
      loading: false,
      namesearch: "",
      namesearch2: "",
      list_paged: [],
      page: 1,
      page2: 1,
      pagestat: 1,
      count: 1,
      count2: 1,
      pageSize: 10,
      pageSize2: 10,
      prodnumber: "",
      pageSizes: [10, 50, 100, 500],
      pageSizes2: [10, 50, 100, 500],
    };
  },
  created() {
    this.fetchData();
    this.prodnumber = this.$route.params.id;
  },
  methods: {
    handlePageChange(value) {
      this.page = value;
      this.pagestat = 1;
      // window.scrollTo({ top: 0, behavior: "smooth" });
      if (this.namesearch == "") {
        this.fetchData();
      } else {
        this.searchdata();
      }
    },

    handlePageSizeChange(event) {
      this.pageSize = event.target.value;
      this.page = 1;
      if (this.namesearch == "") {
        this.fetchData();
      } else {
        this.searchdata();
      }
    },

    getRequestParams(page, pageSize) {
      let params = {};

      if (page) {
        params["page"] = page;
      }

      if (pageSize) {
        params["rowcount"] = pageSize;
      }

      return params;
    },

    formatPrice(value) {
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },

    async fetchData() {
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget =
        this.$apiurl +
        "proses_produksi_d_material/getalldataproses_produksi_d_material?length=" +
        this.pageSize +
        "&page=" +
        this.page +
        "&nomor_produksi=" +
        this.$route.params.id;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin,
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((respdetail) => {
          if (respdetail.data.data.length == 0) {
            this.isExist = false;
            this.loading = false;
          } else {
            this.isExist = true;
            this.list_paged = respdetail.data.data;
            this.count = respdetail.data.datatotalcount;
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data,
          });
        });
    },
  },
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
